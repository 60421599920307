@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300&display=swap");
@import'bigSlider.css';
@import 'button.css';
@import 'card.css';
@import 'footer.css';
@import 'megaMenu.css';
@import 'navMenuDesktop.css';
@import 'navMenuMobile.css';
@import 'typo.css';
@import 'megaMenuMobile.css';
@import 'responsive.css';
@import 'megaMenuAll.css';
@import 'footer.css';
@import 'onboard.css';
@import 'common.css';
@import 'productDetails.css';
@import 'cartList.css';



body {
    background-color: #ffffff;
}

.notification-card {
    cursor: pointer !important;
}