.section-title {
    margin-top: 50px;
    margin-bottom: 50px;
    color: #051b35;
    font-size: 20px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
}

.section-title-contact {
    color: #051b35;
    font-size: 18px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
}

.section-title-login {
    margin-top: 50px;
    margin-bottom: 10px;
    color: #051b35;
    font-size: 30px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 600;
}

.section-sub-title {
    color: #212121;
    font-size: 15px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
}

.product-name-on-card {
    color: #051b35;
    font-size: 14px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
}
.product-price-on-card {
    color: #e43023;
    font-size: 14px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 600;

}

.category-name {
    color: #000000;
    font-size: 13px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 600;
}

.text-link {
    color: inherit;
    text-decoration: inherit;
}

.breadbody {
    background-color: #ddd;
    padding-top: 20px;
    padding-bottom: 7px;
    padding-left: 30px;
    font-weight: 600;
    border-radius: 10px;
}

.userprofile {
    width: 200px;
    height: 200px;
    margin-left: 0;
}


/*

    tags input styles 
*/

.tags-input-container {
    width: min(80vw, 600px);
    margin-top: 1em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5em;
}

.tag-item {
    background-color: rgb(211, 209, 209);
    display: inline-block;
    padding: .5em, .75em;
    border-radius: 20px;
    cursor: pointer;

}

.tag-item .close {
    height: 20px;
    width: 20px;
    background-color: rgb(151, 144, 144);
    display: inline-flex;
    color: #fff;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-left: .5em;
    font-size: 18px;
}

.tags-input {
    flex-grow: 1;
    padding: .5em, 0;
    border: none;
    outline: none;
}


/**

    This is builtin tags input styles
*/

.container {
    margin: auto;
    width: 50%;
  }

.ReactTags__tags {
    position: relative;
  }
  
  /* Styles for the input */
  .ReactTags__tagInput {
    width: 200px;
    border-radius: 2px;
    display: inline-block;
  }
  .ReactTags__tagInput input.ReactTags__tagInputField,
  .ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 31px;
    margin: 0;
    font-size: 12px;
    width: 100%;
    border: 1px solid #eee;
  }
  
  /* Styles for selected tags */
  .ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #63bcfd;
    color: white;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    border-radius: 2px;
  }
  .ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
  }
  
  /* Styles for suggestions */
  .ReactTags__suggestions {
    position: absolute;
  }
  .ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    background: white;
    width: 200px;
  }
  .ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
  }
  .ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  .ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
  }
  
  .ReactTags__remove {
    border: none;
    cursor: pointer;
    background: none;
    color: white;
  }
  